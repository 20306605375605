import React from "react"
import { graphql /* , navigate */ } from "gatsby"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Header4 from "../components/contents/header4"

import { Container } from "react-bootstrap"

import Content1 from "../content/gruppo/gruppo_content1"
import Content2 from "../content/gruppo/gruppo_content2"

import { scrollPage } from "../components/functions/index"

const GruppoPage = ({ data, location }) => {
  const { state = {} } = location
  scrollPage(state.is_scroll, state.path, state.time)

  return (
    <Layout
      header={
        <Header4
          src_sfondo={data.file.childImageSharp.fluid}
          title={data.site.siteMetadata.gruppo.title}
          link_page={data.site.siteMetadata.gruppo.link_page}
        />
      }
      location={location}
    >
      <SEO
        title={data.site.siteMetadata.gruppo.title}
        pathname={data.site.siteMetadata.gruppo.link_page}
      />
      <Container fluid>
        <div id="societa_controllate">
          <Content1 />
        </div>
        <div id="societa_partecipate">
          <Content2 />
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { regex: "/gruppo/header/" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        gruppo {
          title
          seo_title
          link_page
        }
      }
    }
  }
`

export default GruppoPage
