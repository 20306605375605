import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { /* Container, */ Col, Row } from "react-bootstrap"
import ConvertLink from "../../components/htmls/ConvertLink"
/* import Img from "gatsby-image" */

import MyStyles from "./content.module.css"

import ScrollAnimation from "react-animate-on-scroll"

const pathLoghi = [
  "archimede-energia-e-servizi",
  "archimede-research",
  "https://unikore.it/index.php/it/distretti/distretto-energia-edilizia-sostenibile",
  "leonardo",
  "http://www.pstsicilia.org/",
  "qohelet",
]
const isLinkExtern = path => {
  if (path.includes("http")) {
    return true
  } else {
    return false
  }
}

const GruppoCont = () => (
  /*  ...GatsbyImageSharpFluid */
  <StaticQuery
    query={graphql`
      {
        allFile(
          filter: { relativeDirectory: { eq: "index/CarouselGruppo" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 300, quality: 80, jpegProgressive: true) {
                  src
                }
              }
            }
          }
          totalCount
        }
      }
    `}
    render={data => (
      <>
        <div>
          <Row className="vh-100 align-items-center text-center ">
            <Col>
              <ScrollAnimation
                duration={0.5}
                animateIn="fadeIn"
                animateOut="fadeOut"
              >
                <Row className="p-4">
                  {data.allFile.edges.map(({ node }, index) => {
                    if (isLinkExtern(pathLoghi[index])) {
                      return (
                        <Col key={index} sm={12} md={12} xl={6}>
                          <ConvertLink
                            key={index}
                            path={pathLoghi[index]}
                            index={index}
                          >
                            <img
                              className="img-fluid rounded shadow mx-auto my-2"
                              alt={pathLoghi[index]}
                              src={node.childImageSharp.fluid.src}
                            />

                            {/* <Img
                        fluid={node.childImageSharp.fluid}
                        
                        alt=""
                      /> */}
                          </ConvertLink>
                        </Col>
                      )
                    } else {
                      return <div key={index}></div>
                    }
                  })}
                </Row>
              </ScrollAnimation>
            </Col>
            <Col className="h-100">
              <Row
                className="h-100 align-items-center"
                style={{
                  backgroundColor: `#003781`,
                }}
              >
                <Col>
                  <ScrollAnimation
                    animateIn="bounceInRight"
                    animateOut="bounceOutLeft"
                  >
                    <div
                      className={`display-3 ${MyStyles.text_responsive_white}`}
                    >
                      Società partecipate
                    </div>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* <Container className="mb-4">
          <h2
            style={{
              textAlign: `center`,
              paddingTop: `2rem`,
            }}
          >
            Il Gruppo
          </h2>
          <BannerCarousel
            images={data.allFile.edges}
            paths={pathLoghi}
          ></BannerCarousel>
        </Container> */}
      </>
    )}
  />
)

export default GruppoCont
